







import { Component, Prop, Vue } from 'vue-property-decorator';
import { SecondAuthData } from '@/const/contents/auth';
import ModalIframe from '@/components/iframe/ModalIframe.vue';

@Component({ components: { ModalIframe } })
export default class AuthSecondPopup extends Vue {
  @Prop({ required: true })
  private secondAuthData: SecondAuthData;

  private get remoteSrc() {
    const queryObject = { 
      enableOtp: this.secondAuthData.target.enableOtp?.toString(), 
      certKey:this.secondAuthData.key?.toString(),
      mobile: this.secondAuthData.target.mobile?.toString(),
      plan: this.secondAuthData.plan?.toString(),
      types: (this.secondAuthData.types ?? []).join(','),
    };
    const queryString = new URLSearchParams(queryObject).toString();
    const src = process.env.VUE_APP_ADMIN_REMOTE_URL + '/popup/security-authentication?' + queryString;

    return src;
  }

  private receiveMessage(e: MessageEvent) {
      
    if (e.data.closeAuthSecond) {
      
      this.$emit('closePopup');
      return;
    }

    if(e.data.completeSecondAuth){
      this.$emit('completeSecondAuth', e.data.data);
    }
  }

  mounted() {
    window.addEventListener('message', this.receiveMessage);
  }

  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  }


}
